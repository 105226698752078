:root {
  color-scheme: light dark;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 16px;
  --space-4: 32px;
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 1200px;
  --container-width: 1600px;
  --masthead-height: 50px;
  --button-box-shadow: 0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05);
  --completion-diameter: 1.4em;
  --sans-serif: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
  /* Opacity */
  --op-default: 0.9;
  --op-muted: 0.6;
  --op-disabled: 0.35;
  /* Colors */
  --purple: hsl(270, 60%, 50%);
  --purple-lighter: hsl(270, 60%, 60%);
  --mustard: hsl(50, 100%, 42%);
  --bright-green: hsl(73, 100%, 40%);
  --bright-green-muted: hsla(73, 100%, 40%, 20%);
  --deep-green: hsl(73, 100%, 20%);
  --red: hsl(0, 100%, 60%);
  --bg0: hsl(0, 0%, 100%);
  --bg1: hsl(0, 0%, 97%);
  --bg2: hsl(0, 0%, 94%);
  --bg3: hsl(0, 0%, 91%);
  --bg4: hsl(0, 0%, 88%);
  --bg5: hsl(0, 0%, 82%);
  --on-bg: rgba(12, 12, 12, var(--op-default));
  --on-bg-muted: rgba(12, 12, 12, var(--op-muted));
  --on-bg-disabled: rgba(12, 12, 12, var(--op-disabled));
  --elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --elevation-2: 0px 2px 4px rgba(0, 0, 0, 0.1);
  --elevation-3: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.dark {
  --purple: hsl(270, 40%, 60%);
  --purple-lighter: hsl(270, 40%, 70%);
  --mustard: hsl(50, 100%, 50%);
  --bright-green: hsl(73, 100%, 40%);
  --bright-green-muted: hsla(73, 100%, 40%, 20%);
  --deep-green: hsl(73, 100%, 20%);
  --red: hsl(0, 100%, 60%);
  --bg0: hsl(0, 0%, 6%);
  --bg1: hsl(0, 0%, 9%);
  --bg2: hsl(0, 0%, 12%);
  --bg3: hsl(0, 0%, 15%);
  --bg4: hsl(0, 0%, 18%);
  --bg5: hsl(0, 0%, 21%);
  --on-bg: white;
  --on-bg-muted: rgba(255, 255, 255, var(--op-muted));
  --on-bg-disabled: rgba(255, 255, 255, var(--op-disabled));
  --elevation-1: 0px 1px 2px rgba(0, 0, 0, 0.2);
  --elevation-2: 0px 2px 4px rgba(0, 0, 0, 0.4);
  --elevation-3: 0px 8px 16px rgba(0, 0, 0, 0.8);
}
.dark .invert {
   filter: invert(100%);
}
/* FONTS */
body {
    font-family: var(--sans-serif);
    line-height: 1.5;
    background-color: var(--bg0);
    color: var(--on-bg);
}
.strong { font-weight: 600; }
h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
}
.muted {
    opacity: var(--op-muted);
}
.disabled {
    opacity: var(--op-disabled);
}
/* LAYOUT */
body {
    margin: 0;
}
* {
    box-sizing: border-box;
}
.container {
    max-width: var(--container-width);
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    margin-left: auto;
    margin-right: auto;
}
.section {
    padding-bottom: var(--space-4);
    padding-top: var(--space-4);
    border-top: 1px solid var(--bg4);
}
@media (max-width: 42rem) {  /* TODO standardize */
    padding-left: var(--space-2);
    padding-right: var(--space-2);
}
textarea {
    box-sizing: initial;
    width: calc(100% - 24px); /* TODO unhack */
    background: var(--bg3);
    color: var(--on-bg);
}
textarea:focus {
    outline: none;
}
td {
    vertical-align: top;
}
p {
    margin-top: 0;
    margin-bottom: 0.5em;
    line-height: 1.5em;
}
table {
    border-collapse: collapse;
}
.panel-narrow {
    max-width: var(--width-3);
    margin: auto;
    padding-left: var(--space-3);
    padding-right: var(--space-3);
}
.center {
    text-align: center;
}
.right {
    text-align: right;
}
a, .link-color {
    text-decoration: none;
    color: var(--purple);
}
a:hover, .link-color:hover {
    text-decoration: underline;
}
.masthead {
    top: 0;
    width: 100%;
    height: var(--masthead-height);
    line-height: var(--masthead-height);
    border-bottom: 1px solid var(--bg2);
}
.masthead a {
    color: var(--on-bg-muted);
}
.masthead a:hover {
    color: var(--on-bg);
    text-decoration: none;
}
.masthead .logo {
    opacity: var(--op-muted);
}
.masthead a:hover .logo {
    opacity: var(--op-default);
}
.tab {
    display: inline-block;
    padding-left: var(--space-3);
    padding-right: var(--space-3);
    line-height: var(--masthead-height) !important;  /* overcome material icon */
}
.tab .material-symbols-outlined {
    width: 24px;
}
.active.tab {
    color: var(--on-bg);
    user-select: none;
    border-bottom: 1px solid var(--bg1);
    border-left: 1px solid var(--bg2);
    border-right: 1px solid var(--bg2);
    margin-bottom: -1px;
}
.message {
    margin-bottom: var(--space-1);
    padding-top: var(--space-1);
    padding-bottom: var(--space-2);
}
.message.success {
    color: var(--on-bg);
    background-color: var(--deep-green);
}
.footer {
    background-color: var(--bg1);
    box-shadow: 0 50vh 0 50vh var(--bg1);
    font-size: 0.9em;
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
    color: var(--on-bg-disabled);
}
.footer a {
    color: var(--on-bg-muted);
}
.divider-bottom {
    border-bottom: 1px solid #d7d7d7;
}
.text-smaller {
    font-size: 0.9em;
    line-height: 1.5;
}
.opacity-lower {
    opacity: 0.85;
}
.opacity-lowest {
    opacity: 0.6;
}
.hide {
    display: none;
}
input[type='text'], input[type='password'], input[type='email'] {
    display: block;
    width: 100%;
    font-size: 1.1em;
    padding: var(--space-2);
    border: 0px;
    box-shadow: inset 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background: var(--bg1);
    color: var(--on-bg);
}
.errorlist {
    max-width: 20rem;
    color: var(--red);
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 0.9em;
}
button {
    cursor: pointer;
}
.button-contained {
    border: 0;
    font-weight: 500;
    text-transform: uppercase;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding: 0 16px;
}
.button {
    display: inline-block;
    background-color: var(--purple);
    font-weight: bold;
    user-select: none;
    border-radius: 9999px;
    box-shadow: var(--button-box-shadow);
    padding: 0 var(--space-3);
    color: var(--bg1);
    border: none;
    line-height: 36px;
    font-size: 18px;
}
.button:active {
    outline: none;
}
.button:hover {
    text-decoration: none;
}
.button:hover:not([disabled]) {
    background-color: var(--purple-lighter);
}
.button[disabled] {
    cursor: default;
    opacity: var(--op-disabled);
}
.button-plain {
    outline: none;
    user-select: none;
    background: transparent;
    border: none;
    padding: 0;
}
.button-plain i {
    line-height: 32px;
}
.button-vote {
    color: hsl(0, 0%, 20%);
    opacity: var(--op-muted);
    height: 32px;
    width: 32px;
    position: relative;
}
.button-vote:hover {
    opacity: 1;
}
.button-vote i {
    line-height: 48px;
    font-size: 48px;
    position: absolute;
    left: -8px;
    top: -8px;
}
.button-secondary {
    border: 2px solid var(--purple);
    color: var(--purple);
    background: transparent;
}
.button-secondary:hover, .button-secondary:hover:not([disabled]) {
    background-color: transparent;
    color: var(--purple-lighter);
    border-color: var(--purple-lighter);
}
.select {
    background: var(--bg0);
    color: var(--on-bg);
    padding: var(--space-2) var(--space-2) var(--space-2) var(--space-1);
    border-radius: 7px;
    border: 1px solid var(--bg4);
}
textarea {
    border: 0px;
    font-size: 1em;
    line-height: 1.4em;
    resize: none;
    padding: 8px 12px;
    font-family: var(--sans-serif);
}
.UserSubmission > :first-child {
    margin-top: 0px;
}
.Response p, .CommentEditor p {
    tab-size: 4;
}
.Spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -50px;
    width: 100px;
    height: 100px;
}
.Spinner.static {
    position: static;
    margin: 0;
}
.Spinner.small {
    height: 20px;
    width: 20px;
}
.Spinner circle {
    stroke: rgba(255, 255, 255, 0.8);
    animation: dash 1.5s ease-in-out infinite;
}
.Spinner.black circle {
    stroke: rgba(0, 0, 0, 0.3);
}
/* Basscss */
.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.mx0 { margin-left:   0; margin-right:  0 }
.my0 { margin-top:    0; margin-bottom: 0 }
.m1  { margin:        var(--space-1) }
.mt1 { margin-top:    var(--space-1) }
.mr1 { margin-right:  var(--space-1) }
.mb1 { margin-bottom: var(--space-1) }
.ml1 { margin-left:   var(--space-1) }
.mx1 { margin-left:   var(--space-1); margin-right:  var(--space-1) }
.my1 { margin-top:    var(--space-1); margin-bottom: var(--space-1) }
.m2  { margin:        var(--space-2) }
.mt2 { margin-top:    var(--space-2) }
.mr2 { margin-right:  var(--space-2) }
.mb2 { margin-bottom: var(--space-2) }
.ml2 { margin-left:   var(--space-2) }
.mx2 { margin-left:   var(--space-2); margin-right:  var(--space-2) }
.my2 { margin-top:    var(--space-2); margin-bottom: var(--space-2) }
.m3  { margin:        var(--space-3) }
.mt3 { margin-top:    var(--space-3) }
.mr3 { margin-right:  var(--space-3) }
.mb3 { margin-bottom: var(--space-3) }
.ml3 { margin-left:   var(--space-3) }
.mx3 { margin-left:   var(--space-3); margin-right:  var(--space-3) }
.my3 { margin-top:    var(--space-3); margin-bottom: var(--space-3) }
.m4  { margin:        var(--space-4) }
.mt4 { margin-top:    var(--space-4) }
.mr4 { margin-right:  var(--space-4) }
.mb4 { margin-bottom: var(--space-4) }
.ml4 { margin-left:   var(--space-4) }
.mx4 { margin-left:   var(--space-4); margin-right:  var(--space-4) }
.my4 { margin-top:    var(--space-4); margin-bottom: var(--space-4) }
.mxn1 { margin-left: calc(var(--space-1) * -1); margin-right: calc(var(--space-1) * -1); }
.mxn2 { margin-left: calc(var(--space-2) * -1); margin-right: calc(var(--space-2) * -1); }
.mxn3 { margin-left: calc(var(--space-3) * -1); margin-right: calc(var(--space-3) * -1); }
.mxn4 { margin-left: calc(var(--space-4) * -1); margin-right: calc(var(--space-4) * -1); }
.m-auto  { margin: auto; }
.mt-auto { margin-top: auto }
.mr-auto { margin-right: auto }
.mb-auto { margin-bottom: auto }
.ml-auto { margin-left: auto }
.mx-auto { margin-left: auto; margin-right: auto; }
.my-auto { margin-top: auto; margin-bottom: auto; }
.p0  { padding: 0 }
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }
.p1  { padding:        var(--space-1) }
.pt1 { padding-top:    var(--space-1) }
.pr1 { padding-right:  var(--space-1) }
.pb1 { padding-bottom: var(--space-1) }
.pl1 { padding-left:   var(--space-1) }
.py1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
.px1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }
.p2  { padding:        var(--space-2) }
.pt2 { padding-top:    var(--space-2) }
.pr2 { padding-right:  var(--space-2) }
.pb2 { padding-bottom: var(--space-2) }
.pl2 { padding-left:   var(--space-2) }
.py2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
.px2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }
.p3  { padding:        var(--space-3) }
.pt3 { padding-top:    var(--space-3) }
.pr3 { padding-right:  var(--space-3) }
.pb3 { padding-bottom: var(--space-3) }
.pl3 { padding-left:   var(--space-3) }
.py3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
.px3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }
.p4  { padding:        var(--space-4) }
.pt4 { padding-top:    var(--space-4) }
.pr4 { padding-right:  var(--space-4) }
.pb4 { padding-bottom: var(--space-4) }
.pl4 { padding-left:   var(--space-4) }
.py4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
.px4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }
.flex { display: flex }
.flex-column  { flex-direction: column }
.flex-wrap    { flex-wrap: wrap }
.items-start    { align-items: flex-start }
.items-end      { align-items: flex-end }
.items-center   { align-items: center }
.items-baseline { align-items: baseline }
.items-stretch  { align-items: stretch }
.self-start    { align-self: flex-start }
.self-end      { align-self: flex-end }
.self-center   { align-self: center }
.self-baseline { align-self: baseline }
.self-stretch  { align-self: stretch }
.justify-start   { justify-content: flex-start }
.justify-end     { justify-content: flex-end }
.justify-center  { justify-content: center }
.justify-between { justify-content: space-between }
.justify-around  { justify-content: space-around }
.justify-evenly  { justify-content: space-evenly }
.content-start   { align-content: flex-start }
.content-end     { align-content: flex-end }
.content-center  { align-content: center }
.content-between { align-content: space-between }
.content-around  { align-content: space-around }
.content-stretch { align-content: stretch }
/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
    flex: 1 1 auto;
    min-width: 0; /* 1 */
    min-height: 0; /* 1 */
}
.flex-none { flex: none }
.order-0 { order: 0 }
.order-1 { order: 1 }
.order-2 { order: 2 }
.order-3 { order: 3 }
.order-last { order: 99999 }
.VideoPlayer {  /* full width container for both video and controls */
    background: black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
}
.VideoPlayer-display {  /* video, prompts, spinner */
    position: relative;
    flex: 1 0;
}
.VideoPlayer-bottom {  /* controls, progress bar, annotations etc */
    width: 100%;
    flex: 0 0;
    background-color: var(--bg1);
    box-shadow: var(--elevation-1);
}
.fullscreen .VideoPlayer-bottom {
    background-color: transparent;
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.95));
    position: absolute;
    bottom: 0;
}
.VideoPlayer-controls {
    margin: auto;
    max-width: var(--container-width);
    padding: 10px 20px 10px;
    width: 100%;
    box-sizing: border-box;
}
.fullscreen .VideoPlayer-controls {
    max-width: none;
    color: white;
}
.VideoPlayer video {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    outline: none;
}
/* BBC alternative speaker colors https://www.bbc.co.uk/accessibility/forproducts/guides/subtitles/#Colours */
/* next would be 00ffff and 00ff00 */
.VideoPlayer-subtitle .subtitle-student { color: #ffff00; }
.VideoPlayer-promptBackground {
    display: flex;
    justify-content: center;
    animation: fade-in 0.5s ease;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    height: 100%;
    position: absolute;
}
.Prompt {
    background-color: var(--bg5);
    color: var(--on-bg);
    box-shadow: var(--elevation-2);
}
@keyframes fade-in {
    0% { opacity: 0; }
    100% {opacity: 1; }
}
.VideoPlayer-progressControlContainer {
    margin-bottom: 3px;
    position: relative;
}
.VideoPlayer-time {
    position: relative;
    top: -3px;
    font-variant-numeric: tabular-nums;
}
.VideoPlayer-time span {
    opacity: var(--op-disabled);
}
.VideoPlayer-time .VideoPlayer-timeElapsed {
    opacity: 1;
}
.MovableCaptions {
    cursor: move;
    position: absolute;
    max-width: 1024px;
    bottom: 20px;
    left: 25%;
    z-index: 100;
}
.VideoPlayer-subtitle {
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    font-size: 18px;
    display: inline-block;
    padding: 5px 10px;
}
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
.VideoPlayer-controlRow {
    display: flex;
    align-items: center;
    user-select: none;
}
.VideoPlayer-playPause {
    margin-left: -5px;
    margin-right: 15px;
}
.VideoVolumeControl .VideoVolumeControl-slider {
    display: none;
    background-color: var(--bg5);
    box-shadow: var(--elevation-2);
    border-radius: 4px;
    margin-left: -4px;
    margin-bottom: 40px;
}
.VideoVolumeControl:hover .VideoVolumeControl-slider {
    display: block;
}
.VideoPlayer-controlButton {
    cursor: pointer;
}
.VideoPlayer-controlRowRight {
    flex-grow: 1;
    text-align: right;
}
.Slider {
    cursor: pointer;
    position: relative;
    padding: 10px 0;
}
.Slider-groove {
    height: 5px;
    background: var(--bg4);
    position: relative;
}
.VideoPlayer-buffer {
    background: var(--bg5);
    height: 100%;
    position: absolute;
}
.dark .Slider-groove { background: hsl(0, 0%, 30%); }
.dark .VideoPlayer-buffer { background: hsl(220, 0%, 60%); }

.Slider-handle {
    transform: translateX(-50%);
    width: 11px;
    height: 11px;
    border-radius: 100px;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 7px;
    z-index: 400;
}
.Slider-progress {
    background-color: var(--bright-green);
}
.VideoPlayer-fullscreen, .VideoPlayer-sourceChanger, .VideoPlayer-speedChanger, .VideoPlayer-toggleCaptions {
    vertical-align: middle;
    float: right;
    margin-left: 15px;
}
.VideoPlayer-fullscreen {
    margin-right: -4px;
}
.VideoPlayer-toggleCaptions {
    font-weight: bold;
    border: none;
    border-radius: 4px;
    background: var(--on-bg-disabled);
    color: var(--bg0);
    position: relative;
    bottom: -3px;
}
.VideoPlayer-toggleCaptions.active {
    background: var(--on-bg);
}
.VideoPlayer-speedChoices, .VideoPlayer-sourceChoices {
    text-align: left;
    position: absolute;
    bottom: 40px;
    transform: translateX(-10px);
    display: none;
}
.VideoPlayer-speedChoices > div, .VideoPlayer-sourceChoices > div {
    padding: 2px 0;
    opacity: 0.6;
    cursor: pointer; 
}
.VideoPlayer-speedChoices > div:hover, .VideoPlayer-sourceChoices > div:hover {
    opacity: 1;
}
.VideoPlayer-speedChanger:hover .VideoPlayer-speedChoices,
.VideoPlayer-speedChanger div:hover {
    display: block;
}
.VideoPlayer-sourceChanger:hover .VideoPlayer-sourceChoices,
.VideoPlayer-sourceChanger div:hover {
    display: block;
}
.profile-picture {
    vertical-align: middle;
    border-radius: 9999px
}
.no-text-decoration, .no-text-decoration:hover {
    text-decoration: none;
}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table
}
.clearfix:after { clear: both }
.col {
    float: left;
    box-sizing: border-box;
}
.col-right {
    float: right;
    box-sizing: border-box;
}
.col-1 {
    width: calc(1/12 * 100%);
}
.col-2 {
    width: calc(2/12 * 100%);
}
.col-3 {
    width: calc(3/12 * 100%);
}
.col-4 {
    width: calc(4/12 * 100%);
}
.col-5 {
    width: calc(5/12 * 100%);
}
.col-6 {
    width: calc(6/12 * 100%);
}
.col-7 {
    width: calc(7/12 * 100%);
}
.col-8 {
    width: calc(8/12 * 100%);
}
.col-9 {
    width: calc(9/12 * 100%);
}
.col-10 {
    width: calc(10/12 * 100%);
}
.col-11 {
    width: calc(11/12 * 100%);
}
.col-12 {
    width: 100%;
}
@media (min-width: 40em) {
    .sm-col {
        float: left;
        box-sizing: border-box;
    }
    .sm-col-right {
        float: right;
        box-sizing: border-box;
    }
    .sm-col-1 {
        width: calc(1/12 * 100%);
    }
    .sm-col-2 {
        width: calc(2/12 * 100%);
    }
    .sm-col-3 {
        width: calc(3/12 * 100%);
    }
    .sm-col-4 {
        width: calc(4/12 * 100%);
    }
    .sm-col-5 {
        width: calc(5/12 * 100%);
    }
    .sm-col-6 {
        width: calc(6/12 * 100%);
    }
    .sm-col-7 {
        width: calc(7/12 * 100%);
    }
    .sm-col-8 {
        width: calc(8/12 * 100%);
    }
    .sm-col-9 {
        width: calc(9/12 * 100%);
    }
    .sm-col-10 {
        width: calc(10/12 * 100%);
    }
    .sm-col-11 {
        width: calc(11/12 * 100%);
    }
    .sm-col-12 {
        width: 100%;
    }
}
@media (min-width: 52em) {
    .md-right {
        text-align: right;
    }
    .md-justify-end {
        justify-content: end;
    }
    .md-col {
        float: left;
        box-sizing: border-box;
    }
    .md-col-right {
        float: right;
        box-sizing: border-box;
    }
    .md-col-1 {
        width: calc(1/12 * 100%);
    }
    .md-col-2 {
        width: calc(2/12 * 100%);
    }
    .md-col-3 {
        width: calc(3/12 * 100%);
    }
    .md-col-4 {
        width: calc(4/12 * 100%);
    }
    .md-col-5 {
        width: calc(5/12 * 100%);
    }
    .md-col-6 {
        width: calc(6/12 * 100%);
    }
    .md-col-7 {
        width: calc(7/12 * 100%);
    }
    .md-col-8 {
        width: calc(8/12 * 100%);
    }
    .md-col-9 {
        width: calc(9/12 * 100%);
    }
    .md-col-10 {
        width: calc(10/12 * 100%);
    }
    .md-col-11 {
        width: calc(11/12 * 100%);
    }
    .md-col-12 {
        width: 100%;
    }
.md-p0  { padding: 0 }
.md-pt0 { padding-top: 0 }
.md-pr0 { padding-right: 0 }
.md-pb0 { padding-bottom: 0 }
.md-pl0 { padding-left: 0 }
.md-px0 { padding-left: 0; padding-right:  0 }
.md-py0 { padding-top: 0;  padding-bottom: 0 }
.md-p1  { padding:        var(--space-1) }
.md-pt1 { padding-top:    var(--space-1) }
.md-pr1 { padding-right:  var(--space-1) }
.md-pb1 { padding-bottom: var(--space-1) }
.md-pl1 { padding-left:   var(--space-1) }
.md-py1 { padding-top:    var(--space-1); padding-bottom: var(--space-1) }
.md-px1 { padding-left:   var(--space-1); padding-right:  var(--space-1) }
.md-p2  { padding:        var(--space-2) }
.md-pt2 { padding-top:    var(--space-2) }
.md-pr2 { padding-right:  var(--space-2) }
.md-pb2 { padding-bottom: var(--space-2) }
.md-pl2 { padding-left:   var(--space-2) }
.md-py2 { padding-top:    var(--space-2); padding-bottom: var(--space-2) }
.md-px2 { padding-left:   var(--space-2); padding-right:  var(--space-2) }
.md-p3  { padding:        var(--space-3) }
.md-pt3 { padding-top:    var(--space-3) }
.md-pr3 { padding-right:  var(--space-3) }
.md-pb3 { padding-bottom: var(--space-3) }
.md-pl3 { padding-left:   var(--space-3) }
.md-py3 { padding-top:    var(--space-3); padding-bottom: var(--space-3) }
.md-px3 { padding-left:   var(--space-3); padding-right:  var(--space-3) }
.md-p4  { padding:        var(--space-4) }
.md-pt4 { padding-top:    var(--space-4) }
.md-pr4 { padding-right:  var(--space-4) }
.md-pb4 { padding-bottom: var(--space-4) }
.md-pl4 { padding-left:   var(--space-4) }
.md-py4 { padding-top:    var(--space-4); padding-bottom: var(--space-4) }
.md-px4 { padding-left:   var(--space-4); padding-right:  var(--space-4) }
}
@media (min-width: 64em) {
    .lg-col {
        float: left;
        box-sizing: border-box;
    }
    .lg-col-right {
        float: right;
        box-sizing: border-box;
    }
    .lg-col-1 {
        width: calc(1/12 * 100%);
    }
    .lg-col-2 {
        width: calc(2/12 * 100%);
    }
    .lg-col-3 {
        width: calc(3/12 * 100%);
    }
    .lg-col-4 {
        width: calc(4/12 * 100%);
    }
    .lg-col-5 {
        width: calc(5/12 * 100%);
    }
    .lg-col-6 {
        width: calc(6/12 * 100%);
    }
    .lg-col-7 {
        width: calc(7/12 * 100%);
    }
    .lg-col-8 {
        width: calc(8/12 * 100%);
    }
    .lg-col-9 {
        width: calc(9/12 * 100%);
    }
    .lg-col-10 {
        width: calc(10/12 * 100%);
    }
    .lg-col-11 {
        width: calc(11/12 * 100%);
    }
    .lg-col-12 {
        width: 100%;
    }
}
.tag {
    background: var(--on-bg-muted);
    color: var(--bg0);
    display: inline-block;
    padding: 0.2em 0.4em 0.2em;
    border-radius: 4px;
    text-transform: uppercase;
    box-shadow: 0px 1px 2px rgba(0 0 0 / 20%);
    line-height: 1em;
    user-select: none;
}
.tag.mustard {
    background: var(--mustard);
    color: var(--bg0);
}
.description .tag {
    font-size: 0.9em;
}
/* COLLAPSIBLE */
.collapsible-content p { margin-bottom: 0 }
.collapsible-input { display: none; }
.collapsible-content { max-height: 72px; overflow-y: hidden; transition: max-height .1s ease-in-out; }
.collapsible-input:checked + .collapsible-content { max-height: 100vh; }
.collapsible-input ~ label { display: inline-block; margin-top: 3px; cursor: pointer }
.collapsible-input:checked ~ label { display: none; }
/* DROP MENU */
.drop-menu {
    position: relative;
}
.drop-menu ul {
    list-style-type: none;
    display: none;
    position: absolute;
    padding: 0 var(--space-3);
    right: 0;
    z-index: 1000;
    margin: 0;
    text-align: right;
    line-height: 2;
    background: var(--bg1);
    box-shadow: var(--elevation-2);
}
.drop-menu:hover ul {
    display: block;
}
/* COMPLETION CHECKBOX */
/* hide actual checkbox... will style span in label instead */
input[type='checkbox'].completion { height: 0; width: 0; margin: 0; padding: 0; visibility: hidden; }
input[type='checkbox'].completion + label{
    position: relative;
    display: flex;
    align-items: center;
}
/* the circle */
input[type='checkbox'].completion + label > span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--completion-diameter);
    height: var(--completion-diameter);
    background: transparent;
    border: 2px solid var(--on-bg-muted);
    border-radius: 20px;
    transition: all 250ms cubic-bezier(.4,.0,.23,1);
    position: relative;
}
input[type='checkbox']:not([disabled='disabled']).completion + label:hover > span {
    background: var(--bright-green-muted);
    cursor: pointer;
}
/* checked circle */
input[type='checkbox'].completion:checked + label > span{
    border: calc(0.5 * var(--completion-diameter)) solid var(--bright-green);
    animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
}
/* the actual tick */
input[type='checkbox'].completion:checked + label > span:before{
    content: "";
    position: absolute;
    top: -0.16em;
    left: -0.35em;
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
}
@keyframes shrink-bounce{
    0%{ transform: scale(1); }
    33%{ transform: scale(.85); }
    100%{ transform: scale(1); }
}
@keyframes checkbox-check{
    0%{
        width: 0;
        height: 0;
        border-color: var(--bg0);
        transform: translate3d(0,0,0) rotate(45deg);
    }
    33%{
        width: .2em;
        height: 0;
        transform: translate3d(0,0,0) rotate(45deg);
    }
    100%{
        width: .2em;
        height: .5em;
        border-color: var(--bg0);
        transform: translate3d(0,-.5em,0) rotate(45deg);
    }
}
/* User responses */
.Response {
    background: var(--bg1);
    box-shadow: var(--elevation-1);
}
/* Comment editor */
.CommentEditor {
    box-shadow: var(--elevation-1);
    background: var(--bg3);
    border-radius: 7px;
    /* overflow: hidden; this would make the corners tidier but hides tooltip */
}
.CommentEditor-top {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding-left: var(--space-2);
    padding-right: var(--space-2);
    padding-top: var(--space-2);
    font-size: 0.9em;
    line-height: 16px;
    color: var(--on-bg-muted);
    background: var(--bg2);
}
.CommentEditor-top-aside {
    font-size: 0.9em;
    padding: var(--space-2);
    text-align: right;
}
.CommentEditor-tab {
    display: inline-block;
    padding: var(--space-2) var(--space-3);
    margin-right: var(--space-2);
    margin-bottom: -1px;
    border: 1px solid transparent;
}
.CommentEditor-tab.active {
    background: var(--bg3);
    border-bottom: none;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.CommentEditor-tab:not(.active) {
    cursor: pointer;
}
.CommentEditor-content {
    background: var(--bg3);
    padding-top: var(--space-2);
}
.CommentEditor-bottom {
    padding: var(--space-1);
    text-align: right;
    align-items: center;
}
.CommentEditor-bottom button, .reply-button {
    font-size: 0.9em;
    line-height: 2em;
    padding: 0 var(--space-2);
    border-radius: 7px;
}
.CommentEditor-bottom .ResponseTextarea-status {
    height: 20px;
    padding-right: var(--space-2);
}
.ResponseTextarea-status.error {
    color: red;
}
.CommentEditor :not(.previewing) .CommentEditor-preview {
    display: none;
}
.CommentEditor .previewing textarea {
    display: none;
}
.CommentEditor-preview {
    min-height: 2em;
    padding: 0 var(--space-2);
}
.CommentEditor .Tooltip-bubble {
    text-align: left;
    bottom: 30px;
    left: 50%;
}
.simple-tabs {
    border-bottom: 1px solid var(--bg4);
}
.simple-tabs .tab {
    line-height: unset !important;
    display: inline-block;
    padding: var(--space-2) var(--space-3);
    margin-right: var(--space-2);
    margin-bottom: -1px;
    border: 1px solid transparent;
}
.simple-tabs .tab.active {
    border: 1px solid var(--bg4);
    border-bottom: 1px solid var(--bg0);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: unset;
}
.simple-tabs .tab:not(.active) {
    cursor: pointer;
}
.tab-panel {
    display: none;
}
.tab-panel.active {
    display: block;
}
/* TOOLTIPS */
.Tooltip {
    position: relative;
    z-index: 200;
}
.Tooltip-bubble {
    text-align: center;
    user-select: none;
    font-size: 10px;
    position: absolute;
    display: none;
    white-space: nowrap;
    bottom: 6px;
    transform: translateX(-50%);
    padding-bottom: 14px;
    z-index: 300;
}
.Tooltip:hover {
    z-index: 400;
}
.Tooltip-bubbleInner {
    border-radius: 6px;
    background-color: var(--bg5);
    box-shadow: var(--elevation-2);
    padding: var(--space-1) var(--space-2);
    position: relative;
}
.Tooltip-triangle {
    content:'';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top: 6px solid var(--bg5);
    bottom: 8px;
    left: 50%;
    transform: translateX(-5px);
}
.Tooltip-marker {
}
.Tooltip-showing .Tooltip-bubble, .Tooltip:hover .Tooltip-bubble {
    display: block;
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.Tooltip-showing .Tooltip-bubble {
    animation: fadein 1s ease-out;
}
.PausePointTooltips .Tooltip {
    position: absolute;
    top: 2px;
    padding: 8px 7px;
    height: 21px;
    transform: translateX(-50%);
    width: 19px;
}
.PausePointTooltips .Tooltip-bubble {
    pointer-events: none;
    left: 7.5px;
    bottom: 10px;
}
.PausePointTooltips .Tooltip-marker {
    background-color: var(--bg1);
    height: 100%;
}
.VideoPlayer-noteMarkers {
    height: 8px;
    margin-bottom: 2px;
}
.VideoPlayer-noteMarkers .Tooltip {
    position: absolute;
    height: 8px;
    width:  8px;
    padding: 2px;
    transform: translateX(-50%);
}
.VideoPlayer-noteMarkers .Tooltip-marker {
    background-color: var(--on-bg);
    border-radius: 99px;
    height: 100%;
    opacity: var(--op-disabled);
}
.VideoPlayer-noteMarkers .Tooltip-bubble {
    width: max-content;
    max-width: 300px;
    text-align: left;
    white-space: unset;
    font-size: 0.9em;
}
.TimestampTooltip {
    bottom: 12px;
    pointer-events: none;
}
/* Expandable */
.Expandable {
    position: relative;
}
.Expandable-expander {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, 1) 90%);
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Expandable-expander .button {
    font-size: 11px;
    line-height: 18px;
    background-color: var(--bg5);
    color: var(--on-bg);
    padding: 0 var(--space-2);
}
/* article/outline layout */
body {
    counter-reset: sidenote-counter;
}
.main-text { width: 66.666% }
.sidenote, .marginnote {
    float: right;
    clear: right;
    margin-right: -50%;
    width: 45%;
    margin-top: 0;
    margin-bottom: var(--space-4);
    padding-left: var(--space-4);
    font-size: 13px;
    line-height: 1.3;
    vertical-align: baseline;
    position: relative;
    color: var(--on-bg-muted);
}
.sidenote-number {
    counter-increment: sidenote-counter;
}
.sidenote-number:after, .sidenote:before {
    position: relative;
vertical-align: baseline; }
.sidenote-number:after {
    content: counter(sidenote-counter);
    font-size: 13px;  /* hardcoded to match sidebar number */
    top: -0.5rem;
    left: 0.1rem;
}
/* The number of the sidenote */
.sidenote:before {
    content: counter(sidenote-counter) " ";
    display: inline-block;
    position: absolute;
    transform: translateX(-100%);
    padding-right: 0.9em;
    line-height: 13px;
}
.quote {
    display: block;
    color: var(--mustard);
    font-size: 28px;
    line-height: 1.5em;
    font-family: Georgia, serif;
}
.quote .inner {
    display: block;
    padding-top: var(--space-3);
    padding-bottom: var(--space-2);
    border-top: 3px solid;
    border-bottom: 3px solid;
}
.quote cite {
    display: block;
    text-align: right;
    font-size: 16px;
}
.spoiler:not(:hover), .spoiler:not(:hover) * {
    background-color: var(--bg4);
    color: transparent !important;
}
.chip {
    display: inline-block;
    height: 1.5em;
    user-select: none;
    font-size: 1.5em;
    color: var(--on-bg);
}
.chip:hover {
    text-decoration: none;
}
.chip img {
    height: 100%;
    margin-right: var(--space-2);
}


.codehilite {
    padding: 1px 16px;
    background-color: var(--bg3);
}
.codehilite pre {
    white-space: pre-wrap;
}
.codehilite .hll { background-color: #ffffcc }
.codehilite .c { color: #408080; font-style: italic } /* Comment */
.codehilite .k { color: #008000; font-weight: bold } /* Keyword */
.codehilite .o { color: #666666 } /* Operator */
.codehilite .cm { color: #408080; font-style: italic } /* Comment.Multiline */
.codehilite .cp { color: #BC7A00 } /* Comment.Preproc */
.codehilite .c1 { color: #408080; font-style: italic } /* Comment.Single */
.codehilite .cs { color: #408080; font-style: italic } /* Comment.Special */
.codehilite .gd { color: #A00000 } /* Generic.Deleted */
.codehilite .ge { font-style: italic } /* Generic.Emph */
.codehilite .gr { color: #FF0000 } /* Generic.Error */
.codehilite .gh { color: #000080; font-weight: bold } /* Generic.Heading */
.codehilite .gi { color: #00A000 } /* Generic.Inserted */
.codehilite .go { color: #808080 } /* Generic.Output */
.codehilite .gp { color: #000080; font-weight: bold } /* Generic.Prompt */
.codehilite .gs { font-weight: bold } /* Generic.Strong */
.codehilite .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
.codehilite .gt { color: #0040D0 } /* Generic.Traceback */
.codehilite .kc { color: #008000; font-weight: bold } /* Keyword.Constant */
.codehilite .kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
.codehilite .kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
.codehilite .kp { color: #008000 } /* Keyword.Pseudo */
.codehilite .kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
.codehilite .kt { color: #B00040 } /* Keyword.Type */
.codehilite .m { color: #666666 } /* Literal.Number */
.codehilite .s { color: #BA2121 } /* Literal.String */
.codehilite .na { color: #7D9029 } /* Name.Attribute */
.codehilite .nb { color: #008000 } /* Name.Builtin */
.codehilite .nc { color: #0000FF; font-weight: bold } /* Name.Class */
.codehilite .no { color: #880000 } /* Name.Constant */
.codehilite .nd { color: #AA22FF } /* Name.Decorator */
.codehilite .ni { color: #999999; font-weight: bold } /* Name.Entity */
.codehilite .ne { color: #D2413A; font-weight: bold } /* Name.Exception */
.codehilite .nf { color: #0000FF } /* Name.Function */
.codehilite .nl { color: #A0A000 } /* Name.Label */
.codehilite .nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
.codehilite .nt { color: #008000; font-weight: bold } /* Name.Tag */
.codehilite .nv { color: #19177C } /* Name.Variable */
.codehilite .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
.codehilite .w { color: #bbbbbb } /* Text.Whitespace */
.codehilite .mf { color: #666666 } /* Literal.Number.Float */
.codehilite .mh { color: #666666 } /* Literal.Number.Hex */
.codehilite .mi { color: #666666 } /* Literal.Number.Integer */
.codehilite .mo { color: #666666 } /* Literal.Number.Oct */
.codehilite .sb { color: #BA2121 } /* Literal.String.Backtick */
.codehilite .sc { color: #BA2121 } /* Literal.String.Char */
.codehilite .sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
.codehilite .s2 { color: #BA2121 } /* Literal.String.Double */
.codehilite .se { color: #BB6622; font-weight: bold } /* Literal.String.Escape */
.codehilite .sh { color: #BA2121 } /* Literal.String.Heredoc */
.codehilite .si { color: #BB6688; font-weight: bold } /* Literal.String.Interpol */
.codehilite .sx { color: #008000 } /* Literal.String.Other */
.codehilite .sr { color: #BB6688 } /* Literal.String.Regex */
.codehilite .s1 { color: #BA2121 } /* Literal.String.Single */
.codehilite .ss { color: #19177C } /* Literal.String.Symbol */
.codehilite .bp { color: #008000 } /* Name.Builtin.Pseudo */
.codehilite .vc { color: #19177C } /* Name.Variable.Class */
.codehilite .vg { color: #19177C } /* Name.Variable.Global */
.codehilite .vi { color: #19177C } /* Name.Variable.Instance */
.codehilite .il { color: #666666 } /* Literal.Number.Integer.Long */

.dark .codehilite .hll { background-color: #272822; }
.dark .codehilite .c { color: #75715e } /* Comment */
.dark .codehilite .k { color: #66d9ef } /* Keyword */
.dark .codehilite .l { color: #ae81ff } /* Literal */
.dark .codehilite .n { color: #f8f8f2 } /* Name */
.dark .codehilite .o { color: #f92672 } /* Operator */
.dark .codehilite .p { color: #f8f8f2 } /* Punctuation */
.dark .codehilite .cm { color: #75715e } /* Comment.Multiline */
.dark .codehilite .cp { color: #75715e } /* Comment.Preproc */
.dark .codehilite .c1 { color: #75715e } /* Comment.Single */
.dark .codehilite .cs { color: #75715e } /* Comment.Special */
.dark .codehilite .ge { font-style: italic } /* Generic.Emph */
.dark .codehilite .gs { font-weight: bold } /* Generic.Strong */
.dark .codehilite .kc { color: #66d9ef } /* Keyword.Constant */
.dark .codehilite .kd { color: #66d9ef } /* Keyword.Declaration */
.dark .codehilite .kn { color: #f92672 } /* Keyword.Namespace */
.dark .codehilite .kp { color: #66d9ef } /* Keyword.Pseudo */
.dark .codehilite .kr { color: #66d9ef } /* Keyword.Reserved */
.dark .codehilite .kt { color: #66d9ef } /* Keyword.Type */
.dark .codehilite .ld { color: #e6db74 } /* Literal.Date */
.dark .codehilite .m { color: #ae81ff } /* Literal.Number */
.dark .codehilite .s { color: #e6db74 } /* Literal.String */
.dark .codehilite .na { color: #a6e22e } /* Name.Attribute */
.dark .codehilite .nb { color: #f8f8f2 } /* Name.Builtin */
.dark .codehilite .nc { color: #a6e22e } /* Name.Class */
.dark .codehilite .no { color: #66d9ef } /* Name.Constant */
.dark .codehilite .nd { color: #a6e22e } /* Name.Decorator */
.dark .codehilite .ni { color: #f8f8f2 } /* Name.Entity */
.dark .codehilite .ne { color: #a6e22e } /* Name.Exception */
.dark .codehilite .nf { color: #a6e22e } /* Name.Function */
.dark .codehilite .nl { color: #f8f8f2 } /* Name.Label */
.dark .codehilite .nn { color: #f8f8f2 } /* Name.Namespace */
.dark .codehilite .nx { color: #a6e22e } /* Name.Other */
.dark .codehilite .py { color: #f8f8f2 } /* Name.Property */
.dark .codehilite .nt { color: #f92672 } /* Name.Tag */
.dark .codehilite .nv { color: #f8f8f2 } /* Name.Variable */
.dark .codehilite .ow { color: #f92672 } /* Operator.Word */
.dark .codehilite .w { color: #f8f8f2 } /* Text.Whitespace */
.dark .codehilite .mf { color: #ae81ff } /* Literal.Number.Float */
.dark .codehilite .mh { color: #ae81ff } /* Literal.Number.Hex */
.dark .codehilite .mi { color: #ae81ff } /* Literal.Number.Integer */
.dark .codehilite .mo { color: #ae81ff } /* Literal.Number.Oct */
.dark .codehilite .sb { color: #e6db74 } /* Literal.String.Backtick */
.dark .codehilite .sc { color: #e6db74 } /* Literal.String.Char */
.dark .codehilite .sd { color: #e6db74 } /* Literal.String.Doc */
.dark .codehilite .s2 { color: #e6db74 } /* Literal.String.Double */
.dark .codehilite .se { color: #ae81ff } /* Literal.String.Escape */
.dark .codehilite .sh { color: #e6db74 } /* Literal.String.Heredoc */
.dark .codehilite .si { color: #e6db74 } /* Literal.String.Interpol */
.dark .codehilite .sx { color: #e6db74 } /* Literal.String.Other */
.dark .codehilite .sr { color: #e6db74 } /* Literal.String.Regex */
.dark .codehilite .s1 { color: #e6db74 } /* Literal.String.Single */
.dark .codehilite .ss { color: #e6db74 } /* Literal.String.Symbol */
.dark .codehilite .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
.dark .codehilite .vc { color: #f8f8f2 } /* Name.Variable.Class */
.dark .codehilite .vg { color: #f8f8f2 } /* Name.Variable.Global */
.dark .codehilite .vi { color: #f8f8f2 } /* Name.Variable.Instance */
.dark .codehilite .il { color: #ae81ff } /* Literal.Number.Integer.Long */
.dark .codehilite .gh { } /* Generic Heading & Diff Header */
.dark .codehilite .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
.dark .codehilite .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
.dark .codehilite .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */

